import React from "react";

const FilePlaceHolder = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="166"
    height="140"
    viewBox="0 0 166 140"
    {...props}
  >
    <defs>
      <clipPath id="clip-Image">
        <rect width="166" height="140" />
      </clipPath>
    </defs>
    <g id="Image" clipPath="url(#clip-Image)">
      <g
        id="Grupo_26625"
        data-name="Grupo 26625"
        transform="translate(-482 86)"
      >
        <rect
          id="Retângulo_1"
          data-name="Retângulo 1"
          width="145"
          height="120"
          rx="8"
          transform="translate(492 -76)"
          fill="#f7f7f8"
        />
        <g
          id="Grupo_1506"
          data-name="Grupo 1506"
          transform="translate(542 -33)"
        >
          <g
            id="Grupo_26624"
            data-name="Grupo 26624"
            transform="translate(0 0)"
          >
            <path
              id="Caminho_38188"
              data-name="Caminho 38188"
              d="M76.386,147.449H38.542c-1.861,0-3.376,1.184-3.376,2.639v29.586c0,1.455,1.514,2.639,3.376,2.639H76.386c1.861,0,3.376-1.184,3.376-2.639V150.088C79.762,148.633,78.247,147.449,76.386,147.449Z"
              transform="translate(-35.166 -147.449)"
              fill="#fff"
            />
          </g>
          <path
            id="Caminho_38190"
            data-name="Caminho 38190"
            d="M71.7,204.584H40.5a1.194,1.194,0,0,1-1.193-1.193v-8.2l5.875-4.865,5.252,5.252a.795.795,0,0,0,1.124,0L64.05,183.088l8.84,8.84v11.463A1.194,1.194,0,0,1,71.7,204.584Z"
            transform="translate(-33.802 -173.939)"
            fill="#f7f7f8"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default FilePlaceHolder;
