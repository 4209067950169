import React from "react";

const Upload = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="116"
    viewBox="0 0 120 116"
  >
    <defs>
      <clipPath id="clip-Importar_">
        <rect width="120" height="116" />
      </clipPath>
    </defs>
    <g id="Importar_" data-name="Importar " clipPath="url(#clip-Importar_)">
      <g
        id="Grupo_1505"
        data-name="Grupo 1505"
        transform="translate(-660 -263)"
      >
        <g
          id="Grupo_1503"
          data-name="Grupo 1503"
          transform="translate(670.049 273.184)"
        >
          <path
            id="Caminho_2193"
            data-name="Caminho 2193"
            d="M1862.76,570.029h11.508a24.671,24.671,0,1,0-9.827-47.864,24.672,24.672,0,0,0-47.672,12.569c-.228-.007-.453-.033-.68-.033a17.686,17.686,0,1,0,0,35.373H1833.3"
            transform="translate(-1798.399 -504.782)"
            fill="none"
            stroke="#2e323c"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <g
            id="Grupo_1502"
            data-name="Grupo 1502"
            transform="translate(32.183 60.059)"
          >
            <g id="Grupo_1501" data-name="Grupo 1501">
              <path
                id="Caminho_2194"
                data-name="Caminho 2194"
                d="M1818.863,557.61V543.657H1808.28l17.318-20.436,17.312,20.436h-10.585V557.61"
                transform="translate(-1808.28 -523.221)"
                fill="none"
                stroke="#2e323c"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
        <g
          id="Grupo_1504"
          data-name="Grupo 1504"
          transform="translate(741.569 295.451)"
        >
          <path
            id="Caminho_2195"
            data-name="Caminho 2195"
            d="M1841.521,529.975a18.3,18.3,0,0,0-21.165-18.133,56.1,56.1,0,0,1,7.429,8.5,35.9,35.9,0,0,1,6.742,18.335,27.174,27.174,0,0,1-.377,5.993A18.369,18.369,0,0,0,1841.521,529.975Z"
            transform="translate(-1820.357 -511.618)"
            fill={color}
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Upload;
