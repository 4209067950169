import * as React from "react";

function CheckCircle({ colorCheck, colorCircle, props }) {
  return (
    <svg width={31} height={31} viewBox="0 0 31 31" {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h31v31H0z" />
        </clipPath>
      </defs>
      <g data-name="Web 1920 \u2013 1">
        <g data-name="Grupo 28471" clipPath="url(#prefix__a)">
          <g data-name="Grupo 27119">
            <g data-name="Grupo 26895">
              <rect
                data-name="Ret\xE2ngulo 661"
                width={29}
                height={29}
                rx={14.5}
                transform="translate(1 .999)"
                fill={colorCircle}
              />
            </g>
          </g>
          <g data-name="Grupo 27120">
            <path
              data-name="Caminho 37698"
              d="M20.863 10.597l-8.254 8.254L10 16.242a.5.5 0 00-.705.706l2.962 2.961a.5.5 0 00.705 0l8.606-8.606a.5.5 0 10-.705-.7z"
              fill={colorCheck}
              stroke={colorCheck}
              strokeWidth={1.3}
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckCircle;
