import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    borderRadius: "50%",
    position: "absolute",
    transition: ".3s ease",
    zIndex: "1"
  }
});

const Elipse = ({
  background,
  left,
  top,
  width,
  height,
  right,
  bottom,
  className
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{
        background: background,
        left: left,
        top: top,
        right: right,
        width: width,
        height: height,
        bottom: bottom
      }}
    ></div>
  );
};

export default Elipse;
